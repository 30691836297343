import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ChatThreadMessage } from '../models/chat-thread-message';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getMessages(): Observable<ChatThreadMessage[]> {
    return this.http.get<ChatThreadMessage[]>(`${this.apiUrl}/message/`);
  }

  getMessage(messageId: number): Observable<ChatThreadMessage> {
    return this.http.get<ChatThreadMessage>(
      `${this.apiUrl}/message/${messageId}/`
    );
  }

  createMessage(messageData: ChatThreadMessage): Observable<ChatThreadMessage> {
    return this.http.post<ChatThreadMessage>(
      `${this.apiUrl}/message/`,
      messageData
    );
  }

  deleteMessage(messageId: number): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/message/${messageId}/`);
  }
}
