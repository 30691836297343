<ion-split-pane when="lg" contentId="main-content" [disabled]="!isLoggedIn">
  <ion-menu #menu contentId="main-content" type="overlay">
    <!-- <ion-header>
      <ion-toolbar>
        <ion-button (click)="newChat()" expand="block" fill="outline">
          <ion-icon name="add-outline" slot="start"></ion-icon>
          New chat
        </ion-button>
      </ion-toolbar>
    </ion-header> -->
    <ion-header>
      <ion-toolbar>
        <!-- <ion-list lines="none">
          <ion-item class="heading">
            <ion-icon name="cog-outline"></ion-icon>
            <ion-label>Settings</ion-label>
          </ion-item>
        </ion-list> -->
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding setting-view">
      <ion-list lines="none">
        <!-- <ion-item class="heading">
          <ion-icon name="cog-outli7ne"></ion-icon>
          <ion-label>Settings</ion-label>
        </ion-item> -->
        <ion-item (click)="newChat()" class="active">
          <ion-icon name="add"></ion-icon>
          <ion-label>New Chat</ion-label>
        </ion-item>
        <ion-item (click)="viewProfile()">
          <ion-icon name="person-circle"></ion-icon>
          <ion-label>My Profile</ion-label>
        </ion-item>
        <ion-item (click)="openNorthProfile()">
          <span class="img"
            ><img
              src="/assets/images/logo-no-name.png"
              alt=""
              height="24"
              width="17"
          /></span>
          <ion-label>North Profile</ion-label>
        </ion-item>
        <ion-item (click)="openArchive()">
          <ion-icon name="archive-outline"></ion-icon>
          <ion-label>Chat History</ion-label>
        </ion-item>
        <ion-item (click)="moodCheckIn()">
          <span class="img"
            ><img
              src="/assets/images/happy-face-stats.svg"
              alt=""
              height="28"
              width="28"
          /></span>
          <ion-label>Mood Check In</ion-label>
        </ion-item>
        <ion-item (click)="openVoiceSettings()">
          <ion-icon name="mic"></ion-icon>
          <ion-label>Voice Mode</ion-label>
        </ion-item>
        <ion-item (click)="openDisplaySettings()">
          <ion-icon name="contrast-outline"></ion-icon>
          <ion-label>Display</ion-label>
        </ion-item>
        <ion-item (click)="openNotificationSettings()">
          <ion-icon name="notifications-outline"></ion-icon>
          <ion-label>Notifications</ion-label>
        </ion-item>
        <ion-item (click)="openFaqs()">
          <ion-icon name="sync-outline"></ion-icon>
          <ion-label>Updates & FAQ</ion-label>
        </ion-item>
        <ion-item (click)="openAbout()">
          <ion-icon name="id-card-outline"></ion-icon>
          <ion-label>Subscription</ion-label>
        </ion-item>
        <ion-item (click)="openSupport()">
          <ion-icon name="mail-outline"></ion-icon>
          <ion-label>Support</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
    <ion-footer>
      <ion-list lines="none">
        <ion-item menu-close button (click)="logout()">
          <ion-icon name="log-out-outline" style="margin-right: 12px">
          </ion-icon>
          <ion-label>Logout</ion-label>
        </ion-item>
      </ion-list>
    </ion-footer>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-split-pane>
