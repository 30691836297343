import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  lastValueFrom,
  Observable,
  Subject,
  Subscription,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { BOT_NAME } from '../consts';
import { ChatReply } from '../models/api-helper';
import { Message } from '../models/message';
import { User } from '../models/user';
import { AuthService } from './auth.service';
import { ChatThreadService } from './chat-thread.service';
import { ChatThread } from '../models/chat-thread';
import { ChatThreadMessage, SentBy } from '../models/chat-thread-message';
import { MessageService } from './message.service';
import { ModalController } from '@ionic/angular';
import { NorthModelSelectorComponent } from '../main/components/north-model-selector/north-model-selector.component';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  baseUrl = environment.baseUrl;
  apiUrl = environment.apiUrl;
  elevenApiKey = environment.elevenApiKey;
  elevenUrl = environment.elevenUrl;
  messages: Message[] = [];
  chatId!: string;
  chatUpdateListener = new BehaviorSubject<boolean>(false);
  private selectedModel = new Subject<string>(); // keep track of selected model

  //hold streamPrompt subscription to unsubscribe when user creates new chat.
  //This will remove the glitch when user clicks '+' icon and previous streaming chat still shows
  //created in service so chat streaming can also be stopped from new chat from side menu
  public streamPromptSub$?: Subscription;

  chatName!: string;
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private chatThreadService: ChatThreadService,
    private messageService: MessageService,
    private modalCtrl: ModalController
  ) {}

  initChatWith(user: User) {
    this.showModelSelector();
    this.messages = [];
    this.messages.push({
      id: '1',
      content: `Hi, ${user.first_name}. How’s it going?`,
      date: new Date(),
      sender: BOT_NAME,
      receiver: user.email,
    });
  }

  async createChatThread(user: User) {
    const chatThread: ChatThread = {
      user_id: user.user_id!,
      title: (await this.getChatTitile(user)).title,
    };
    return await lastValueFrom(this.chatThreadService.createChat(chatThread));
  }

  saveMessage(messageData: ChatThreadMessage) {
    return lastValueFrom(this.messageService.createMessage(messageData));
  }

  //save chat messages to chat archive history
  async saveChatThreadMessages(chatId: number, messages: Message[]) {
    if (messages.length > 5) {
      const messageIndex = messages.length - 2;
      await this.saveMessage({
        chat_id: chatId!,
        sent_by:
          messages[messageIndex].sender === BOT_NAME
            ? SentBy.ASSISTANT
            : SentBy.USER,
        content: messages[messageIndex].content,
      });
      const messageIndex2 = messages.length - 1;
      await this.saveMessage({
        chat_id: chatId!,
        sent_by:
          messages[messageIndex2].sender === BOT_NAME
            ? SentBy.ASSISTANT
            : SentBy.USER,
        content: messages[messageIndex2].content,
      });
    }
    if (messages.length == 5) {
      for (let i = 0; i < messages.length; i++) {
        await this.saveMessage({
          chat_id: chatId!,
          sent_by:
            messages[i].sender === BOT_NAME ? SentBy.ASSISTANT : SentBy.USER,
          content: messages[i].content,
        });
      }
    }
  }

  sendPrompt(
    user: User,
    speech: number,
    model: string
  ): Observable<ChatReply[]> {
    const accessToken = this.authService.getAccessToken();
    if (!accessToken) {
      return new Observable<ChatReply[]>();
    }
    // this.testPrompt(user, speech, model);

    const chats: any = [];
    this.messages.forEach((msg) => {
      if (msg.content == 'Typing...') return;
      chats.push({
        role: msg.sender === BOT_NAME ? 'assistant' : 'user',
        content: msg.content,
      });
    });
    var formData = new FormData();
    const body = {
      date: new Date().toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
      time: new Date().toLocaleTimeString('en-US', { timeStyle: 'medium' }),
      messages: chats,
      speech: speech.toString(),
      model: model,
    };

    // LOG CURRENT TIME TO LOCAL STORAGE
    localStorage.setItem('logtime', Date.now().toString());
    return this.http.post<ChatReply[]>(`${this.apiUrl}/chatbot/reply/`, body);
  }

  streamPrompt(
    user: User,
    speech: number,
    model: string
  ): Observable<string> | null {
    const accessToken = this.authService.getAccessToken();
    if (!accessToken) {
      return null;
    }
    const chats: any = [];
    this.messages.forEach((msg) => {
      if (msg.content == 'Typing...') return;
      chats.push({
        role: msg.sender === BOT_NAME ? 'assistant' : 'user',
        content: msg.content,
      });
    });
    var formData = new FormData();
    const body = {
      date: new Date().toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
      time: new Date().toLocaleTimeString('en-US', { timeStyle: 'medium' }),
      messages: chats,
      speech: speech.toString(),
      model: model,
    };

    const messageSub$ = new Subject<string>();

    fetch(`${this.apiUrl}/chatbot/reply_stream/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
    }).then(async (response) => {
      if (response.body === null) {
        return null;
      }
      const reader = response.body.getReader();

      const decoder = new TextDecoder('utf-8');
      let text = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          messageSub$.complete();
          break;
        }
        const chunk = decoder.decode(value);
        text += chunk;
        messageSub$.next(text);
      }
      return null;
    });

    localStorage.setItem('logtime', Date.now().toString());
    return messageSub$.asObservable();
  }

  elevenApi(text: string): any {
    return this.http.post(
      this.elevenUrl,
      {
        text,
      },
      {
        headers: {
          'xi-api-key': this.elevenApiKey,
        },
        responseType: 'blob',
      }
    );
  }

  async saveChat(user: User) {
    let currentChatThread = this.chatThreadService.currentChatThread;
    //copy messages so that they are saved even if messages are cleared for new chat
    const messages = structuredClone(this.messages);
    if (!currentChatThread) {
      this.chatThreadService.currentChatThread = await this.createChatThread(
        user
      );
      currentChatThread = this.chatThreadService.currentChatThread;
    }

    if (currentChatThread !== null && currentChatThread.chat_id) {
      await this.saveChatThreadMessages(currentChatThread.chat_id, messages);
    }
    this.chatUpdateListener.next(true);
    return;
    // if (!this.chatId || this.chatId.length < 1) {
    //   this.chatId = Date.now().toString();
    // }
    // if (!this.chatName || this.chatName.length < 1) {
    //   this.chatName = `Chat Archive: ${
    //     new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
    //   }`;
    // }
    // const chatArchive = JSON.parse(localStorage.getItem('archive') || '[]');
    // const existingIndex = chatArchive.findIndex(
    //   (chat: any) => chat.id === this.chatId
    // );
    // const existingName =
    //   existingIndex > -1 ? chatArchive[existingIndex].name : '';
    // if (existingIndex > -1) {
    //   chatArchive.splice(existingIndex, 1);
    // }

    // this.chatName =
    //   existingName.length > 0
    //     ? existingName
    //     : (await this.getChatTitile(user)).title;
    // const chatData = {
    //   id: this.chatId,
    //   name: this.chatName,
    //   messages: this.messages,
    // };
    // chatArchive.push(chatData);
    // localStorage.setItem('archive', JSON.stringify(chatArchive));
    // this.chatUpdateListener.next(true);
  }

  getChatUpdateListener(): Observable<boolean> {
    return this.chatUpdateListener.asObservable();
  }

  getChatArchive() {
    return JSON.parse(localStorage.getItem('archive') || '[]');
  }

  // updateChatArchive(chats: any) {
  //   localStorage.setItem('archive', JSON.stringify(chats));
  //   this.chatUpdateListener.next(true);
  // }

  getChatFromArchive(id: string) {
    const chatArchive = JSON.parse(localStorage.getItem('archive') || '[]');
    const chat = chatArchive.find((chat: any) => chat.id === id);
    return chat;
  }

  async getChatTitile(user: User) {
    const accessToken = this.authService.getAccessToken();
    if (!accessToken) {
      return new Observable<ChatReply[]>();
    }
    let body = '';
    this.messages.forEach((msg) => {
      if (msg.content == 'Typing...') return;
      body += `${msg.sender === BOT_NAME ? BOT_NAME : user.first_name}: ${
        msg.content
      }\n`;
    });
    body = '' + user.first_name + '.\n\n' + body;
    var formData = new FormData();
    formData.append('prompt', body);

    return await lastValueFrom(
      this.http.post<any>(`${this.apiUrl}/chatbot/generateTitle/`, formData)
    );
  }

  clearChat() {
    this.messages = [];
    this.chatId = '';
    this.chatName = '';
  }
  reInitChat(user: User) {
    this.clearChat();
    this.initChatWith(user);
  }

  // show modal to select north model to use
  async showModelSelector() {
    const modelSelector = await this.modalCtrl.create({
      component: NorthModelSelectorComponent,
      id: 'north-model-selector-modal',
      backdropDismiss: false,
    });

    await modelSelector.present();

    const { data, role } = await modelSelector.onWillDismiss();

    if (role === 'model-selected') {
      this.selectedModel.next(data);
    }
  }

  getSelectedModel() {
    return this.selectedModel.asObservable();
  }
}
