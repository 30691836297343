import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, IonMenu, NavController } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { ChatService } from '../services/chat.service';
import { UserService } from '../services/user.service';
import { ChatThreadService } from '../services/chat-thread.service';
import { lastValueFrom } from 'rxjs';
import { ChatThread } from '../models/chat-thread';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  isLoggedIn = false;
  @ViewChild('menu') menu!: IonMenu;
  chats: ChatThread[] = [];

  constructor(
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private chatService: ChatService,
    private chatThreadService: ChatThreadService
  ) {}

  ionViewDidLoad() {}
  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn();
    if (!this.isLoggedIn) {
      this.router.navigateByUrl('/auth/login');
    }
    this.userService.userUpdateListener.subscribe(async (res: any) => {
      this.isLoggedIn = this.authService.isLoggedIn();
      if (!this.isLoggedIn) {
        return;
      }
      const user = this.userService.getUser();
      this.chats = await lastValueFrom(
        this.chatThreadService.getChats({ userId: user.user_id })
      );
    });
    this.chatService.getChatUpdateListener().subscribe(async (res: any) => {
      const user = this.userService.getUser();
      this.chats = await lastValueFrom(
        this.chatThreadService.getChats({ userId: user.user_id })
      );
    });
  }
  deleteChat(chatId: number) {
    this.chatThreadService.deleteChat(chatId).subscribe((res: any) => {
      this.chatService.chatUpdateListener.next(true);
    });
  }
  newChat() {
    this.chatService.streamPromptSub$?.unsubscribe();
    const user = this.userService.getUser();
    // this.chatService.saveChat(user);
    this.chatThreadService.currentChatThread = null;
    this.chatService.reInitChat(user);
    this.navCtrl.navigateForward('/main/chat');
    this.menu.close();
  }
  async openChat(id: number) {
    await this.menu.close();
    this.navCtrl.navigateForward(`/main/chat/${id}`);
  }
  async viewProfile() {
    await this.menu.close();
    this.router.navigateByUrl('/main/profile');
  }

  async openNorthProfile() {
    await this.menu.close();
    this.router.navigateByUrl('/main/north-profile');
  }
  async moodCheckIn() {
    await this.menu.close();
    this.router.navigateByUrl('/main/mood-checkin');
  }

  async openSupport() {
    window.open(
      'mailto:support@pointmenorth.com?subject=Support Request',
      '_blank'
    );
  }

  async editChatName(chat: ChatThread) {
    // alert('This functionality will be available soon!');
    // return;
    // const chat = this.chats.find((chat) => chat.chat_id === chatID);
    const alert = await this.alertCtrl.create({
      header: 'Please enter a new chat name',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Ok',
          role: 'ok',
        },
      ],
      inputs: [
        {
          placeholder: 'Title',
          value: chat.title,
        },
      ],
    });
    await alert.present();
    alert.onDidDismiss().then((res) => {
      if (res.role === 'ok') {
        chat.title = res.data.values[0];
        this.chatThreadService
          .updateChat(chat.chat_id || 0, chat)
          .subscribe((res) => {
            this.chatService.chatUpdateListener.next(true);
          });
      }
    });
  }
  openArchive() {
    this.menu.close();
    this.navCtrl.navigateForward(`/main/chat-archive`);
  }
  async logout() {
    await this.menu.close();
    this.authService.logout();
    this.navCtrl.navigateRoot('/auth/login', { replaceUrl: true });
    this.userService.setUser(null);
  }

  async openVoiceSettings() {
    await this.menu.close();
    // this.router.navigateByUrl('/main/voice-mode-settings');

    //send state to show pageTitle
    let navigationExtras: NavigationExtras = {
      state: {
        pageTitle: 'Voice Mode',
      },
    };
    this.router.navigateByUrl('/coming-soon', navigationExtras);
  }

  async openDisplaySettings() {
    await this.menu.close();
    // this.router.navigateByUrl('/main/display-settings');

    let navigationExtras: NavigationExtras = {
      state: {
        pageTitle: 'Display',
      },
    };
    this.router.navigateByUrl('/coming-soon', navigationExtras);
  }

  async openNotificationSettings() {
    await this.menu.close();
    // this.router.navigateByUrl('/main/notifications');

    let navigationExtras: NavigationExtras = {
      state: {
        pageTitle: 'Notifications',
      },
    };
    this.router.navigateByUrl('/coming-soon', navigationExtras);
  }

  async openFaqs() {
    await this.menu.close();
    this.router.navigateByUrl('/main/faqs');
  }

  async openAbout() {
    await this.menu.close();
    this.router.navigateByUrl('/main/plan');
  }
}
