import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { NavController } from '@ionic/angular';

@Injectable()
export class HttpFailureInterceptor implements HttpInterceptor {
  constructor(
    private navCtrl: NavController,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
        },
        error: (err) => {
          if (err.error.error) {
          }
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              console.log(err.message);
              this.authService.logout();
              this.navCtrl.navigateRoot('/auth/login');
            }
            if (err.status === 403) {
              console.log(err.message);
              this.authService.logout();
              this.navCtrl.navigateRoot('/auth/login');
            }
          }
        },
      })
    );
  }
}
