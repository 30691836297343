import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ChatThread } from '../models/chat-thread';
import { Observable, map } from 'rxjs';
import { GetChatsParams } from '../models/get-chats-params';

@Injectable({
  providedIn: 'root',
})
export class ChatThreadService {
  apiUrl = environment.apiUrl;
  currentChatThread!: ChatThread | null;
  constructor(private http: HttpClient) {}
  createChat(chatData: ChatThread): Observable<ChatThread> {
    return this.http.post<ChatThread>(`${this.apiUrl}/chats/`, chatData);
  }
  getChat(chatId: number): Observable<ChatThread> {
    return this.http.get<ChatThread>(`${this.apiUrl}/chats/${chatId}/`);
  }

  getChats({
    userId,
    ordering = '',
    moodFilter = [],
    dateFilter = {
      year: 0,
      month: 0,
      day: 0,
    },
  }: GetChatsParams): Observable<ChatThread[]> {
    let params = new HttpParams();

    // add ordering to params
    if (ordering.length > 0) {
      params = params.set('ordering', ordering);
    }

    // filter mood
    if (moodFilter.length > 0) {
      moodFilter.forEach((mood) => {
        params = params.append('mood', mood);
      });
    }

    if (dateFilter.year > 0) {
      params = params.set('year', dateFilter.year);
    }
    if (dateFilter.month > 0) {
      params = params.set('month', dateFilter.month);
    }
    if (dateFilter.day > 0) {
      params = params.set('day', dateFilter.day);
    }

    return this.http
      .get<ChatThread[]>(`${this.apiUrl}/chats/`, { params: params })
      .pipe(
        map((chats) => {
          return chats.filter((chat) => chat.user_id == userId);
        })
      );
  }
  updateChat(chatId: number, chatData: ChatThread): Observable<ChatThread> {
    return this.http.put<ChatThread>(
      `${this.apiUrl}/chats/${chatId}/`,
      chatData
    );
  }
  deleteChat(chatId: number): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/chats/${chatId}/`);
  }
}
