import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfileData, UserData } from '../models/api-helper';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userUpdateListener = new BehaviorSubject<User | null>(this.getUser());
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getUserUpdateListener() {
    return this.userUpdateListener.asObservable();
  }
  getUser(): User {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }
  setUser(user: User | null) {
    localStorage.setItem('user', JSON.stringify(user));
    this.userUpdateListener.next(user);
  }

  getProfile(id: number) {
    return this.http.get<ProfileData>(`${this.apiUrl}/profiles/${id}/`);
  }

  updateProfile(id: number, data: any): Observable<ProfileData> {
    return this.http.patch<ProfileData>(`${this.apiUrl}/profiles/${id}/`, data);
  }

  updateUser(id: number, data: UserData): Observable<UserData> {
    const formData = new FormData();
    let k: keyof UserData;
    for (k in data) {
      if (data[k]) {
        formData.append(k, String(data[k]));
      }
    }
    return this.http.patch<UserData>(`${this.apiUrl}/users/${id}/`, formData);
  }
}
