import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-north-model-selector',
  templateUrl: './north-model-selector.component.html',
  styleUrls: ['./north-model-selector.component.scss'],
})
export class NorthModelSelectorComponent implements OnInit {
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  selectModel(model: string) {
    this.modalCtrl.dismiss(
      model,
      'model-selected',
      'north-model-selector-modal'
    );
  }
}
