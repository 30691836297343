import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AccessData } from '../models/api-helper';
import { User } from '../models/user';
import { UserService } from './user.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private refreshTimer!: NodeJS.Timeout;
  private activeTimer!: NodeJS.Timeout;
  apiUrl = environment.apiUrl;
  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService
  ) {}

  getTokens(loginData: any) {
    const formData = new FormData();
    Object.keys(loginData).forEach((key: string) => {
      formData.append(key, loginData[key]);
    });
    return this.http.post(`${this.apiUrl}/token/`, formData);
  }
  refreshToken(refreshData: any) {
    const formData = new FormData();
    Object.keys(refreshData).forEach((key: string) => {
      formData.append(key, refreshData[key]);
    });
    return this.http.post(`${this.apiUrl}/tokens/refresh`, formData);
  }

  getAccessToken() {
    const user = JSON.parse(localStorage.getItem('user') || 'null');
    if (!user || user.access.length === 0) {
      return null;
    }
    return user.access;
  }

  getRefreshToken() {
    const user = localStorage.getItem('user');
    if (!user || user.length === 0) {
      return null;
    }
    const userObj = JSON.parse(user) as User;
    return userObj.refresh;
  }

  setTokenExpiryTime() {
    const now = new Date();
    const expiryTime = new Date(now.getTime() + 5 * 60 * 1000);
    localStorage.setItem('expiryTime', expiryTime.toString());
    this.stopRefreshTimer();
    this.startRefreshTimer();
  }

  startRefreshTimer(): void {
    if (this.refreshTimer) {
      this.stopRefreshTimer();
    }
    const expiryTimeString = localStorage.getItem('expiryTime');
    if (expiryTimeString && expiryTimeString.length > 0) {
      const expiryTime = new Date(expiryTimeString);
      const timeout = expiryTime.getTime() - Date.now() - 60 * 1000;
      this.refreshTimer = setTimeout(() => this.refreshTokens(), timeout);
    }
  }

  stopRefreshTimer(): void {
    clearTimeout(this.refreshTimer);
  }

  startActiveTimer(): void {
    if (this.activeTimer) {
      this.stopActiveTimer();
    }
    // create interval of 15 min and check if user is active
    console.log("Timer Started");
    this.activeTimer = setInterval(() => {
      const now = new Date().getTime();
      const lastActiveTime = localStorage.getItem('logtime');
      if (lastActiveTime && now - parseInt(lastActiveTime, 10) >= 15 * 60 * 1000) {
        this.stopActiveTimer();
        this.logout();
        this.router.navigateByUrl('/auth/login');
      }
    }
    , 16 * 60 * 1000);
  }
  stopActiveTimer(): void {
    clearInterval(this.activeTimer);
  }

  getActiveTimer(): NodeJS.Timeout {
    return this.activeTimer;
  }

  public refreshTokens(): void {
    this.stopRefreshTimer();
    const tokenData: any = {
      refresh: this.getRefreshToken() || '',
    };
    const formData = new FormData();
    Object.keys(tokenData).forEach((key: string) => {
      formData.append(key, tokenData[key]);
    });
    this.http
      .post<AccessData>(`${this.apiUrl}/token/refresh/`, formData)
      .subscribe((data: AccessData) => {
        const user = this.userService.getUser();
        if (!user) {
          this.logout();
          return;
        }
        user.access = data.access;
        this.userService.setUser(user);
        this.setTokenExpiryTime();
      });
  }

  logout() {
    this.stopRefreshTimer();
    const rememberMe =
      (localStorage.getItem('rememberMe') || false) == 'false' ? false : true;
    const email = localStorage.getItem('email') || '';
    localStorage.clear();

    localStorage.setItem('rememberMe', rememberMe.toString());
    localStorage.setItem('email', email);
  }

  checkEmailExist(email: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/exist/`, { email: email });
  }

  isLoggedIn(): boolean {
    return !!(this.getAccessToken() || null);
  }
}
